
import { computed, defineComponent, onMounted, PropType, ref, SetupContext, } from 'vue';
import { IOwnerType } from '@/types/Interfaces';
import { cloneDeep } from 'lodash';
import useOwnerType from '@/types/OwnerType'
import { validate, required, isNumber, isColor } from '@/mixins/Validators';

export default defineComponent({
  components: {
  },
  props: {
    modelValue: {
      type: Object as () => IOwnerType
    }
  },
  setup(props: any, context: SetupContext) {
    
    const data = ref<IOwnerType | null>(null);
    
    const {
      createOwnerType,
      updateOwnerType
    } = useOwnerType()

    const errors = ref({
      name: '',
      sort: '',
      bgcolor: '',
      fontcolor: '',
    })
    const validators = {
      name: [
        required
      ],
      sort: [
        required,
        isNumber
      ],
      bgcolor: [
        required,
        isColor
      ],
      fontcolor: [
        required,
        isColor
      ],
    }
    const onClose = () => {
      context.emit('close');  
    };
    const onUpdate = async () => {
      // do some async work
      if (!validate(data, validators, errors)) return;
      if (data.value == null) return;
      let r;
      try {
        if (data.value.id) {
          r = await updateOwnerType(data.value.id, data.value)
        } else {
          r = await createOwnerType(data.value)
        }
      } catch (err) {
        console.log(err.response)
      }
      console.log(r)
      context.emit('update', data.value);
    };

    onMounted(() => {
      data.value = cloneDeep(props.modelValue);
    })

    return {
      data,
      errors,
      onUpdate,
      onClose
    }
  }
})
