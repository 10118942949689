<template>
  <div class="text-gray-900 inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
    <div class="bg-white px-6  pb-4 sm:p-6 sm:pb-4">
      <form
        @submit.prevent="onUpdate"
        v-if="data != null"
        class="pt-6"
      >
        <text-field
          v-model="data.name"
          label="タイプ"
          class="m-5"
          :error="errors.name"
        ></text-field>
        
        <text-field
          v-model="data.sort"
          label="ソート"
          class="m-5"
          :error="errors.sort"
        ></text-field>

        <text-field
          v-model="data.bgcolor"
          label="背景色"
          class="m-5"
          :error="errors.bgcolor"
        ></text-field>
        
        <text-field
          v-model="data.fontcolor"
          label="文字色"
          class="m-5"
          :error="errors.fontcolor"
        ></text-field>
        <div class="text-left">
          <span class="text-sm ml-2">表示</span>
          <my-toggle
          v-model="data.display"
          ></my-toggle>
            
        </div>
        <div>
          <button class="bordered" @click="update">
            {{ data.id ? '更新' : '追加' }}
          </button>
          <button class="ml-5" @click="onClose">
            閉じる
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref, SetupContext, } from 'vue';
import { IOwnerType } from '@/types/Interfaces';
import { cloneDeep } from 'lodash';
import useOwnerType from '@/types/OwnerType'
import { validate, required, isNumber, isColor } from '@/mixins/Validators';

export default defineComponent({
  components: {
  },
  props: {
    modelValue: {
      type: Object as () => IOwnerType
    }
  },
  setup(props: any, context: SetupContext) {
    
    const data = ref<IOwnerType | null>(null);
    
    const {
      createOwnerType,
      updateOwnerType
    } = useOwnerType()

    const errors = ref({
      name: '',
      sort: '',
      bgcolor: '',
      fontcolor: '',
    })
    const validators = {
      name: [
        required
      ],
      sort: [
        required,
        isNumber
      ],
      bgcolor: [
        required,
        isColor
      ],
      fontcolor: [
        required,
        isColor
      ],
    }
    const onClose = () => {
      context.emit('close');  
    };
    const onUpdate = async () => {
      // do some async work
      if (!validate(data, validators, errors)) return;
      if (data.value == null) return;
      let r;
      try {
        if (data.value.id) {
          r = await updateOwnerType(data.value.id, data.value)
        } else {
          r = await createOwnerType(data.value)
        }
      } catch (err) {
        console.log(err.response)
      }
      console.log(r)
      context.emit('update', data.value);
    };

    onMounted(() => {
      data.value = cloneDeep(props.modelValue);
    })

    return {
      data,
      errors,
      onUpdate,
      onClose
    }
  }
})
</script>
