
import { computed, defineComponent, onMounted, ref, SetupContext, } from 'vue';
import { IOwnerRelation, IOwnerType } from '@/types/Interfaces';
import useOwnerType from '@/types/OwnerType';
import useMasterData from '@/types/MasterData';

import OwnerTypeEditForm from './OwnerTypeEditForm.vue';
import useFlashMessage from '@/types/FlashMessage';

export default defineComponent({
  components: {
    OwnerTypeEditForm
  },
  setup(_, context: SetupContext) {
    const {
      setMessage   
    } = useFlashMessage();

    const {
      data,
      editingItem,
      onChangeDisplay
    } = useMasterData();
    
    const {
      fetchOwnerTypes,
      deleteOwnerType
    } = useOwnerType();
    
    onMounted(async () => {
      data.value = await fetchOwnerTypes();
      console.log(data.value)
    })
    

    const addTemplate: IOwnerType = {
      name: '',
      sort: 0,
      bgcolor: '',
      fontcolor: '',
      display: false,
    };
    
    const onUpdate = async () => {
      editingItem.value = null;
      data.value = await fetchOwnerTypes()
      setMessage({ type: 'success', message: '更新しました。' })
    }

    const onDelete = async (id: number) => {
    
      editingItem.value = null;
      const data = await deleteOwnerType(id)
      data.value = await fetchOwnerTypes()
      setMessage({ type: 'success', message: '削除しました。' })
    
    }
    return {
      data,
      editingItem,
      addTemplate,
      onChangeDisplay,
      onUpdate,
      onDelete
    }
  }
})
